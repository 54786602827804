import { I2PEnvironment } from '@price-for-profit/micro-services';
import Apps from '@mui/icons-material/Apps';
import Add from '@mui/icons-material/Add';
import Dashboard from '@mui/icons-material/Dashboard';

const routeConfig: EnvironmentNavigation[] = [
    {
        permission: 'account',
        application: {
            applicationName: 'Drive Account',
            env: {
                local: {
                    applicationId: 'a2077607-9a55-46ac-a00c-d24ed28788cd',
                    deployedUrl: 'https://localhost:5001/apps/account',
                },
                dev: {
                    applicationId: 'a2077607-9a55-46ac-a00c-d24ed28788cd',
                    deployedUrl: 'https://mcc.dev.insight2profit.com/apps/account',
                },
                sandbox: {
                    applicationId: '',
                    deployedUrl: 'https://mcc.sandbox.insight2profit.com/apps/account',
                },
                prod: {
                    applicationId: '',
                    deployedUrl: 'https://mcc.insight2profit.com/apps/account',
                },
            },
        },
        links: [
            {
                title: 'Analytics Dashboard',
                url: '/analytics',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
        ],
    },
    {
        permission: 'quote',
        application: {
            applicationName: 'Deal Management',
            env: {
                local: {
                    applicationId: '6b16d3a3-b7e9-4b3b-b0d0-25d7d30d311f',
                    deployedUrl: 'https://localhost:5003/apps/quote',
                },
                dev: {
                    applicationId: '6b16d3a3-b7e9-4b3b-b0d0-25d7d30d311f',
                    deployedUrl: 'https://mcc.dev.insight2profit.com/apps/quote',
                },
                sandbox: {
                    applicationId: '73fffd9c-5d7a-4c5a-8e6c-0ccf7082698f',
                    deployedUrl: 'https://mcc.sandbox.insight2profit.com/apps/quote',
                },
                prod: {
                    applicationId: 'b9413438-ed8c-4410-a811-d3ae039a7289',
                    deployedUrl: 'https://mcc.insight2profit.com/apps/quote',
                },
            },
        },
        links: [
            {
                title: 'Deal List',
                url: '/quote',
                icon: <Apps sx={{ color: 'primary.main' }} />,
            },
        ],
    },
    {
        permission: 'pd',
        application: {
            applicationName: 'Parameter Dashboard',
            env: {
                local: {
                    applicationId: 'f30d724a-73ea-4c13-8d1d-a01519268b7a',
                    deployedUrl: 'https://localhost:5003/apps/pd',
                },
                dev: {
                    applicationId: 'f30d724a-73ea-4c13-8d1d-a01519268b7a',
                    deployedUrl: 'https://mcc.dev.insight2profit.com/apps/pd',
                },
                sandbox: {
                    applicationId: '17c3eb99-6900-4002-abbf-ffbd383871e4',
                    deployedUrl: 'https://mcc.sandbox.insight2profit.com/apps/pd',
                },
                prod: {
                    applicationId: '5a6b00d5-9b43-4760-a5cb-591779892194',
                    deployedUrl: 'https://mcc.insight2profit.com/apps/pd',
                },
            },
        },
        links: [
            {
                title: 'Scenarios',
                url: '/scenarios',
                icon: <Dashboard sx={{ color: 'primary.main' }} />,
            },
            {
                title: 'New Scenario',
                url: '/scenarios/new',
                icon: <Add sx={{ color: 'primary.main' }} />,
            },
        ],
    },
];

interface NavigationLink {
    title: string;
    url: string;
    icon?: JSX.Element;
}

interface EnvironmentNavigation {
    application: {
        applicationName: string;
        env: Record<Exclude<I2PEnvironment, 'model'> | 'local', { applicationId: string; deployedUrl: string }>;
    };
    links: NavigationLink[];
    permission: string;
}

interface DriveAccountNavigation {
    application: {
        applicationId: string;
        applicationName: string;
        deployedUrl: string;
    };
    links: NavigationLink[];
    local?: boolean;
}

interface BuildDriveAccountNavigationProps {
    applicationId: string;
    environment: Exclude<I2PEnvironment, 'model'>;
    useLocal?: boolean;
    permissionMap: Record<string, boolean>;
}

export function buildDriveAccountNavigation({
    applicationId,
    environment,
    useLocal,
    permissionMap,
}: BuildDriveAccountNavigationProps): DriveAccountNavigation[] {
    const localEnvironment = environment === 'dev' && useLocal ? 'local' : environment;

    return routeConfig.reduce<DriveAccountNavigation[]>((nav, { links, application, permission }) => {
        const envConfig = application.env[localEnvironment];
        if (permissionMap[permission]) {
            nav.push({
                application: {
                    applicationName: application.applicationName,
                    ...envConfig,
                },
                links,
                local: applicationId === envConfig.applicationId,
            });
        }
        return nav;
    }, []);
}

export const showHamburger = routeConfig.length > 1;
