import { useUnsafeUser } from './use-unsafe-user';

export function useUser() {
    const user = useUnsafeUser();

    if (!user) {
        throw new Error('No user found. Make sure useUser is being used as a child of <Private />');
    }

    return user;
}
