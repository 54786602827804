export const CLIENT_ID = 'mcc' as const;
export type ClientId = typeof CLIENT_ID;

export type LoginPermissionKey = 'login';
export type AppPermission<T extends string> = `${ClientId}.${T}`;

export const MUI_LICENSE_KEY =
    '986a355507e186e481e1084cc8541ec8Tz04OTQwNyxFPTE3NDYwMzUyNjEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';

export const SUPPORT_PORTAL_LINK = '';
